<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <!-- <el-form-item label="类型:">
                        <el-select v-model="filters.selectType">
                            <el-option
                                v-for="item in questionswayArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="模板名称:">
                        <el-input v-model='filters.name' placeholder="输入模板名称" clearable @clear='clearContent'></el-input>
                    </el-form-item>
                    <el-form-item label="对应状态:">
                        <el-select v-model="filters.selectStatus" clearable>
                            <el-option
                                v-for="item in selectStatusArr"
                                :key="item.NoticeType"
                                :label="item.NoticeTypeName"
                                :value="item.NoticeType">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="启用标识:">
                        <el-select v-model="filters.isEnable" clearable>
                            <el-option
                                v-for="item in isEnableArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>             
            </el-col>
        </el-row>
        <!--列表-->
        <el-table
            :data="listData"
            highlight-current-row
            v-loading="listLoading"
            border=""
            @current-change="selectCurrentRow"
            style="width: 95%;"
        >
            <el-table-column type="index" width="60" align="center" label="序号"></el-table-column>
            <el-table-column prop="NoticeTypeName" align="center" label="对应状态" min-width="70">
                <!-- <template scope="scope">
                    <span v-if="scope.row.NoticeType == 0">持证监管</span>
                    <span v-if="scope.row.NoticeType == 1">考试</span>
                    <span v-if="scope.row.NoticeType == 2">学习</span>
                    <span v-if="scope.row.NoticeType == 3">宣传推广</span>
                    <span v-if="scope.row.NoticeType == 4">其他</span>
                </template> -->
            </el-table-column>
            <el-table-column prop="Name" align="center" label="模板名称" min-width="200"></el-table-column>
            <!-- <el-table-column prop="NoticeType" align="center" label="类型" min-width="70">
                <template scope="scope">
                    <span v-if="scope.row.NoticeType == 0">持证监管</span>
                    <span v-if="scope.row.NoticeType == 1">考试</span>
                    <span v-if="scope.row.NoticeType == 2">学习</span>
                    <span v-if="scope.row.NoticeType == 3">宣传推广</span>
                    <span v-if="scope.row.NoticeType == 4">其他</span>
                </template>
            </el-table-column> -->
            <el-table-column prop="Content" align="center" label="通知内容" min-width="150"></el-table-column>
            <el-table-column prop="CreateTime" align="center" :formatter="formatCreateTime" label="修改日期" min-width="100"></el-table-column>
            <el-table-column prop="CreateUserName" align="center" label="修改人" width="">
                <template scope="scope">
                    <el-tag>{{scope.row.CreateUserName}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="IsEnable" align="center" label="启用标识" min-width="130">
                <template scope="scope">
                    <el-tag v-if="scope.row.IsEnable" type="success">是</el-tag>
                    <el-tag v-else type="warning">否</el-tag>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="IsSystem" align="center" label="是否系统模板" min-width="130">
                <template scope="scope">
                    <el-tag v-if="scope.row.IsSystem" type="success">是</el-tag>
                    <el-tag v-else type="warning">否</el-tag>
                </template>
            </el-table-column> -->
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <!--新增/编辑界面-->
        <el-dialog
        :title="addOrEdi?'新增':'编辑'"
        :visible.sync="addFormVisible"
        v-model="addFormVisible"
        :close-on-click-modal="false"
        >
            <el-form :model="formData" label-width="150px" :rules="formDataRules" ref="formData">
                <el-form-item label="对应状态："  prop="selectStatus">
                    <el-select v-model="formData.selectStatus">
                        <el-option
                            v-for="item in selectStatusArr"
                            :key="item.NoticeType"
                            :label="item.NoticeTypeName"
                            :value="item.NoticeType">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="模板名称：" prop="dataTitle">
                    <el-input v-model="formData.dataTitle" placeholder="请输入模板名称"></el-input>
                </el-form-item>
                <!-- <el-form-item label="类型："  prop="selectType">
                    <el-select v-model="formData.selectType">
                        <el-option
                            v-for="item in questionswayArr1"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="通知内容：" prop="dataContent">
                    <el-input type="textarea" style="height: 150px;" v-model="formData.dataContent" placeholder="请输入内容"></el-input>
                </el-form-item>
                <el-form-item label="启用标识：" prop="isEnable">
                    <el-select v-model="formData.isEnable">
                        <el-option
                            v-for="item in isEnableArr"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisible = false">取消</el-button>
                <el-button type="primary" @click.native="addSubmit" :loading="addLoading">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Toolbar from "../../components/Toolbar"
import { getButtonList } from "../../promissionRouter";
import { getNoticeTemplateListPage,addNoticeTemplate,updateNoticeTemplate,deleteNoticeTemplate,getNoticeTemplateType } from '../../api/api'
import util from "../../../util/date";
import Qs from 'qs';
export default {
    components: {Toolbar},
    data() {
        return{
            filters: {
                selectStatus: '',
                name: '',
                selectType: '',
                isEnable: '',
            },
            buttonList: [],
            questionswayArr: [
                {value:0,name:'持证监管'},
                {value:1,name:'考试'},
                {value:2,name:'学习'},
                {value:3,name:'宣传推广'},
                {value:4,name:'其他'},
            ],
            questionswayArr1: [
                {value:0,name:'持证监管'},
                {value:1,name:'考试'},
                {value:2,name:'学习'},
                {value:3,name:'宣传推广'},
                {value:4,name:'其他'},
            ],
            listLoading: false,
            listData: [],
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            addOrEdi: true,
            addFormVisible: false,
            addLoading:false,
            formData: {
                selectStatus: '',
                dataTitle: '',
                selectType: '',
                dataContent: '',
                isEnable: '',
            },
            formDataRules: {
                selectStatus: [{required: true, message: "请选择对应状态", trigger: "blur" }],
                dataTitle: [{required: true, message: "请输入模板名称", trigger: "blur" }],
                dataContent: [{required: true, message: "请输入内容", trigger: "blur" }],
                selectType: [{required: true, message: "请选择类型", trigger: "blur" }],
                isEnable: [{required: true, message: "请选择启用标识", trigger: "blur" }],
            },
            editData: '',
            selectStatusArr: [],
            isEnableArr: [
                {value: true, name: "是"},
                {value: false, name: "否"},
            ],
        }
    },
    methods: {
        // 筛选框清空，重新获取数据
        clearContent(){
            this.getListData()
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 获取分页列表
        getListData() {
            var _this = this
            var params = {
                isEnable: this.filters.isEnable,
                noticeType: this.filters.selectStatus,
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            if(this.filters.name){
                params.name = this.filters.name
            }
            // if(this.filters.selectType){
            //     params.noticeType = this.filters.selectType
            // }
            this.listLoading = true;
            getNoticeTemplateListPage(params).then((res) => {
                var result = res.data
                if(result.Success){
                    _this.listLoading = false;
                    this.pages.dataCount = result.Response.DataCount
                    this.pages.pageIndex = result.Response.PageIndex
                    if(result.Response.PageSize > 0){
                        this.pages.pageSize = result.Response.PageSize
                    }
                    this.listData = result.Response.Data
                }
            })
        },
        // 分页
        handleCurrentChange(val) {
            // if(this.filters.name != '' || this.filters.selectType != ''){
            //     this.pages.pageIndex = 1
            // }else{
                this.pages.pageIndex = val;
            // }          
            this.getListData();
        },
        handleSizeChange(val){
            this.pages.pageSize = val           
            this.getListData();
        },
        // 点击table某一行
        selectCurrentRow(val) {
            this.editData = val
        },
        // 查询
        getNoticeTemplateListPage() {
            this.pages.pageIndex = 1
            this.getListData();
        },
        // 新增
        handleAdd() {
            this.formData = {
                dataTitle: '',
                selectType: '',
                selectStatus: '',
                dataContent: '',
                isEnable: '',
            },
            this.addOrEdi = true;
            this.addFormVisible = true;
        },
        // 编辑
        handleEdit() {
            if(this.editData) {
                if(!this.editData.IsSystem) {
                    this.addOrEdi = false;
                    this.addFormVisible = true;
                    this.formData = {
                        dataTitle: this.editData.Name,
                        selectType: this.editData.NoticeType,
                        selectStatus: this.editData.NoticeType,
                        dataContent: this.editData.Content,
                        isEnable: this.editData.IsEnable,
                    }
                }else{
                    this.$message({
                        message: '系统模板不能修改！',
                        type: 'warning'
                    });
                    return;
                }
            }else{
                this.$message({
                    message: '请选择要编辑数据！',
                    type: 'warning'
                });
                return;
            }
        },
        // 删除
        handleDel() {
            if(this.editData) {
                this.$confirm('确定删除？',"提示",{
                }).then(() => {
                    var params = {
                        ID: this.editData.ID
                    }
                    deleteNoticeTemplate(params).then((res) => {
                        var result = res.data
                        if(result.Success){
                            this.$message({
                                message: result.Message,
                                type: 'success'
                            });
                            this.getListData();
                        }else{
                            this.$message({
                                message: result.Message,
                                type: 'error'
                            });
                        }
                    })
                })
                .catch(() => {

                })
            }else{
                this.$message({
                    message: '请选择要删除数据！',
                    type: 'warning'
                });
                return;
            }
        },
        // 新增编辑提交
        addSubmit() {
            var _this = this
            this.$refs.formData.validate(valid => {
                if (valid) {
                    var params = {
                        Name: this.formData.dataTitle,
                        // NoticeType: this.formData.selectType,
                        NoticeType: this.formData.selectStatus,
                        Content: this.formData.dataContent,
                        IsEnable: this.formData.isEnable
                    }
                    if(this.addOrEdi){
                        this.addLoading = true;
                        this.secAddFun(params);
                    }else{
                        params.ID = this.editData.ID
                        this.addLoading = true;
                        this.secEditFun(params);
                    }
                }
            })
        },
        // 新增二次封装
        secAddFun(params) {
            var _this = this
            addNoticeTemplate(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.$message({
                        message: result.Message,
                        type: 'success'
                    });
                    this.getListData();
                    _this.addFormVisible = false;
                    _this.addLoading = false;
                }else{
                    this.$message({
                        message: result.Message,
                        type: 'error'
                    });
                    _this.addLoading = false;
                }
            })
        },
        // 编辑二次封装
        secEditFun(params) {
            var _this = this
            updateNoticeTemplate(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.$message({
                        message: result.Message,
                        type: 'success'
                    });
                    this.getListData();
                    _this.addFormVisible = false;
                    _this.addLoading = false;
                }else{
                    this.$message({
                        message: result.Message,
                        type: 'error'
                    });
                    _this.addLoading = false;
                }
            })
        },
        // 获取对应状态
        getNoticeStatusFun() {
            var params = {}
            getNoticeTemplateType(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.selectStatusArr = result.Response
                }
            })
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
        },
    },
    mounted() {
        this.getListData();
        this.getNoticeStatusFun();
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    },
}
</script>

<style lang="stylus" scoped>
    .el-textarea>>>.el-textarea__inner {
        min-height 150px!important;
    }
</style>